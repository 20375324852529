// For colors, fonts, and other variables
@import '../../../scss/_config.scss';

// 	Steps Styles
.status-bar {
  counter-reset: step;
  padding: 2rem 2rem 0 0;
  ul {
    margin-bottom: 6rem;
    li {
      list-style-type: none;
      float: left;
      position: relative;
      text-align: center;
      font-weight: 600;
      font-size: 0.5rem;
      &:before {
        font-size: 20px;
        font-weight: 700;
        width: 40px;
        height: 40px;
        content: counter(step);
        counter-increment: step;
        line-height: 40px;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: $gray !important;
      }
      &:after {
        width: 100%;
        height: 2px;
        content: "";
        position: absolute;
        background-color: $gray !important;
        top: 20px;
        left: -50%;
        z-index: -1;
      }
      &:first-child:after {
        content: none;
      }
      &.active {
        color: $lightBlue !important;
        font-weight: 700;
        &:before {
          background-color: $lightBlue !important;
          color: $white !important;
        }
        & + li:after {
          background-color: $lightBlue !important;
        }
      }
      &.passed {
        font-weight: 600;
        &:before {
          background-color: $blueGray !important;
          color: $white !important;
        }
        & + li:after {
          background-color: $blueGray !important;
        }
      }
    }
  }
}


//  Styles for the small breakpoint.
@media (min-width: 576px) {

}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {

  .status-bar {
    padding: 2rem 0;
    ul {
      margin-bottom: 1rem;
      li {
        font-size: 1rem;
        &:before {
          font-size: 28px;
          width: 50px;
          height: 50px;
          line-height: 50px;
        }
        &:after {
          top: 25px;
        }
      }
    }
  }

}

//  Styles for the large breakpoint.
@media (min-width: 992px) {

}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {

}

//  Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {

}

//  Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {

}