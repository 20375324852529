// For colors, fonts, and other variables
@import '../../../scss/_config.scss';

// 	Dropdowns Styles
.pseudo-form-control {
  .dropdown-toggle {
      font-size: 0.9rem;
      background: $halfBlue;
      padding: 0.6em 1em;
      display: block;
      border-radius: 0.25rem;
      &:link, &:visited, &:active, &:hover, &:focus {
          color: $placeholderGray;
          text-decoration: none;
      }
      &:after {
          color: $pkBlue;
          float: right;
          margin-top: 0.5rem;
      }
  }
}

.compact-select {
    .dropdown-toggle {
        font-size: 0.85rem;
        font-weight: 600;
        &:link, &:visited, &:active {
            color: $pkBlue;
            text-decoration: none;
        }
        &:hover, &:focus {
            color: $lightBlue;
            text-decoration: none;
        }
    }
    .compact-select-label {
        font-size: 0.85rem;
    }
}

.compact-select, .pseudo-form-control {
  .dropdown-menu {
      font-size: 0.8rem;
      border: 1px solid $gray;
      border-radius: 0.5rem;
      -webkit-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
      -moz-box-shadow:    2px 2px 5px 0px rgba(50, 50, 50, 0.5);
      box-shadow:         2px 2px 5px 0px rgba(50, 50, 50, 0.5);
      .dropdown-item {
          padding: .25rem 1rem;
          &:link, &:visited, &:active {
              color: $darkGray;
              background-color: $white;
              &.semibold-link {
                  font-weight: 600;
              }
              &.selected-item {
                  font-weight: 600;
                  color: $darkGray;
                  background-color: $lightGray;
                  .set-default-link {
                      color: $lightBlue;
                      margin-left: 0.5rem;
                  }
              }
          }
          &:hover, &:focus {
              background-color: $gray;
              &.selected-item {
                  .set-default-link {
                      color: $pkBlue;
                  }
              }
          }
          .energy-icon {
              height: 1.5rem;
              width: auto;
              margin: 0 0.5rem 0 0;
              vertical-align: bottom;
          }
      }
      &.scroll-dropdown {
          padding: 1rem;
          min-width: 18rem;
          .scrolling-area {
              max-height: 20rem;
              overflow-y: scroll;
          }
      }
      &.scroll-dropdown-with-items {
          padding: 0.5rem 1rem 0.5rem 0;
          .scrolling-area {
              max-height: 20rem;
              overflow-y: scroll;
              .container-column {
                  padding-right: 0;
              }
          }
      }
      h3 {
          margin-bottom: 1rem;
      }
  }
}


//  Styles for the small breakpoint.
@media (min-width: 576px) {

}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {

}

//  Styles for the large breakpoint.
@media (min-width: 992px) {

}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {

}

//  Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {

}

//  Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {

}