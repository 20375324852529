// For colors, fonts, and other variables
@import '../../scss/_config.scss';

// 	Sign Up Styles
.nav-icon {
  width: auto;
  height: 1.35rem;
}

.nav-heading {
  font-weight: 600;
  font-size: 1.15rem;
  color: $darkGray;
}

.list-group-item {
  border-bottom: none;
  font-weight: 600;
  font-size: 0.85rem;
}

.offcanvas-header {
  margin-top: 5rem
}

//  Styles for the small breakpoint.
@media (min-width: 576px) {

}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {

}

//  Styles for the large breakpoint.
@media (min-width: 992px) {


}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {

}

//  Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {

}

//  Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {

}