// For colors, fonts, and other variables
@import '../../../scss/_config.scss';

// 	Tables Styles
table {
	thead,
	tbody {
		th,
		td {
			&.min-width-15 {
				min-width: 15rem;
			}
			&.min-width-20 {
				min-width: 20rem;
			}
			&.min-width-25 {
				min-width: 25rem;
			}
		}
	}
	// Smaller Table
	&.table.table-sm {
		td,
		th {
			padding: 3px 7px;
		}
	}
	// Table with gray background
	&.table-gray {
		background-color: rgba(0, 0, 0, 0.05);
	}
	// Table with white background
	&.table-white {
		background-color: #ffffff;
		tr,
		th,
		td {
			background-color: #ffffff;
		}
	}
	.cell-highlight {
		margin-left: -8px;
		margin-right: -8px;
		padding: 8px;
		background-color: #e6f4fb !important;
	}
	// Table with no outer border
	&.table-no-outer-border {
		thead {
      tr {
        border-top: none;
        th {
          border-top: 0;
          text-align: center;
          border-left: 1px solid $darkerOutlineGray;
          border-right: 1px solid $darkerOutlineGray;
          border-bottom: 2px solid $darkerOutlineGray;
          &.no-btm-border {
            border-bottom: 0;
          }
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
		}
		tbody {
			tr {
        &:last-child {
          border-bottom: none;
        }
				td {
					text-align: center;
					border-left: 1px solid $darkerOutlineGray;
					border-right: 1px solid $darkerOutlineGray;
					border-top: 1px solid $darkerOutlineGray;
					border-bottom: 1px solid $darkerOutlineGray;
					&.no-btm-border {
						border-bottom: 0;
					}
					&.no-vert-borders {
						border-top: 0;
						border-bottom: 0;
					}
					&:first-child {
						border-left: 0;
					}
					&:last-child {
						border-right: 0;
					}
				}
				&:last-child {
					td {
						border-bottom: 0;
						padding-bottom: 1.5rem;
					}
				}
			}
		}
		&.table-sm {
			td,
			th {
				padding: 4px 8px;
			}
		}
	}
	&.table-nowrap {
		td,
		th {
			white-space: nowrap;
		}
	}
}

// Control Panel above table
.table-controls {
	background-color: $offRowGray;
	border-bottom: 1px solid $darkGray;
	.table-control-link {
		padding: 0;
		a {
			display: block;
			padding: 0.75rem 15px;
			font-size: 0.85rem;
			font-weight: 600;
			&:link,
			&:visited,
			&:active {
				text-decoration: none;
				i,
				fa-icon {
					color: $blueGray;
					font-size: 1rem;
					vertical-align: baseline;
				}
			}
			&:hover,
			&:focus {
				color: $white;
				background-color: $iconLinkBlue;
				text-decoration: none;
				i,
				fa-icon {
					color: $white;
				}
			}
			span {
				display: none;
			}
			&.disabled {
				&:link,
				&:visited,
				&:active,
				&:hover,
				&:focus {
					color: $outlineGray;
					background-color: $offRowGray;
					cursor: not-allowed;
					i,
					fa-icon {
						color: $outlineGray;
					}
				}
			}
		}
	}
}

//  Styles for the small breakpoint.
@media (min-width: 576px) {

}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {

}

//  Styles for the large breakpoint.
@media (min-width: 992px) {

	// Control Panel above table
	.table-controls {
		.table-control-link {
			a {
				span {
					display: inline;
				}
			}
		}
	}

}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {

}

//  Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {

}

//  Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {

}