// For colors, fonts, and other variables
@import '../../../scss/_config.scss';

// 	Colors Section Styles

// Styling Color Swatch Cards
.card {
  &.color-swatch {
    margin-bottom: 1rem;
    h3 {
      margin-bottom: 1rem;      
    }
    p {
      margin-bottom: 0.5rem;
    }
    &.white {
      color: $darkGray;
      background-color: $white;
      h3 {
        color: $darkGray;
      }
    }
    &.black {
      color: $white;
      background-color: $black;
      h3 {
        color: $white;
      }
    }
    &.pk-blue {
      color: $white;
      background-color: $pkBlue;
      h3 {
        color: $white;
      }
    }
    &.dark-blue {
      color: $white;
      background-color: $darkBlue;
      h3 {
        color: $white;
      }
    }
    &.light-blue {
      color: $white;
      background-color: $lightBlue;
      h3 {
        color: $white;
      }
    }
    &.blue {
      color: $white;
      background-color: $blue;
      h3 {
        color: $white;
      }
    }
    &.half-blue {
      color: $darkGray;
      background-color: $halfBlue;
      h3 {
        color: $darkGray;
      }
    }
    &.light-gray {
      color: $darkGray;
      background-color: $lightGray;
      h3 {
        color: $darkGray;
      }
    }
    &.gray {
      color: $darkGray;
      background-color: $gray;
      h3 {
        color: $darkGray;
      }
    }
    &.dark-gray {
      color: $white;
      background-color: $darkGray;
      h3 {
        color: $white;
      }
    }
    &.orange {
      color: $white;
      background-color: $orange;
      h3 {
        color: $white;
      }
    }
    &.blue-gray {
      color: $white;
      background-color: $blueGray;
      h3 {
        color: $white;
      }
    }
    &.placeholder-gray {
      color: $white;
      background-color: $placeholderGray;
      h3 {
        color: $white;
      }
    }
    &.icon-link-blue {
      color: $white;
      background-color: $iconLinkBlue;
      h3 {
        color: $white;
      }
    }
    &.tooltip-blue {
      color: $darkGray;
      background-color: $tooltipBlue;
      h3 {
        color: $darkGray;
      }
    }
    &.green {
      color: $white;
      background-color: $green;
      h3 {
        color: $white;
      }
    }
    &.light-green {
      color: $darkGray;
      background-color: $lightGreen;
      h3 {
        color: $darkGray;
      }
    }
    &.yellow {
      color: $darkGray;
      background-color: $yellow;
      h3 {
        color: $darkGray;
      }
    }
    &.red {
      color: $white;
      background-color: $red;
      h3 {
        color: $white;
      }
    }
    &.pink {
      color: $darkGray;
      background-color: $pink;
      h3 {
        color: $darkGray;
      }
    }
    &.off-white {
      color: $darkGray;
      background-color: $offWhite;
      h3 {
        color: $darkGray;
      }
    }
    &.silver {
      color: $darkGray;
      background-color: $silver;
      h3 {
        color: $darkGray;
      }
    }
    &.diamond {
      color: $darkGray;
      background-color: $diamond;
      h3 {
        color: $darkGray;
      }
    }
    &.off-row-gray {
      color: $darkGray;
      background-color: $offRowGray;
      h3 {
        color: $darkGray;
      }
    }
    &.outline-gray {
      color: $white;
      background-color: $outlineGray;
      h3 {
        color: $white;
      }
    }
    &.darker-outline-gray {
      color: $white;
      background-color: $darkerOutlineGray;
      h3 {
        color: $white;
      }
    }
    &.faint-pink {
      color: $darkGray;
      background-color: $faintPink;
      h3 {
        color: $darkGray;
      }
    }
    &.faint-orange {
      color: $darkGray;
      background-color: $faintOrange;
      h3 {
        color: $darkGray;
      }
    }
    &.faint-green {
      color: $darkGray;
      background-color: $faintGreen;
      h3 {
        color: $darkGray;
      }
    }
    &.faint-blue {
      color: $darkGray;
      background-color: $faintBlue;
      h3 {
        color: $darkGray;
      }
    }
  }
}

//  Styles for the small breakpoint.
@media (min-width: 576px) {

}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {

}

//  Styles for the large breakpoint.
@media (min-width: 992px) {

}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {

}

//  Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {

}

//  Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {

}