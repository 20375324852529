// 	General Layout Elements

body {
  font-family: $mainFont;
  font-weight: 500;
  color: $darkGray;
  overflow-y: scroll;
}

h1 {
	font-family: $mainFont;
	font-weight: 600;
	color: $pkBlue;
	font-size: 2rem;
	&.add-underline {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 3px;
	}
}

h2 {
	font-family: $mainFont;
	font-weight: 600;
	color: $black;
	font-size: 1.5rem;
	&.add-underline {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 3px;
	}
}

h3 {
	font-family: $mainFont;
	font-weight: 600;
	color: $pkBlue;
	font-size: 1.25rem;
	&.add-underline {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 3px;
	}
}

h4 {
	font-family: $mainFont;
	font-weight: 600;
	color: $black;
	font-size: 1rem;
	&.add-underline {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 3px;
	}
}

h5 {
	font-family: $mainFont;
	font-weight: 600;
	color: $darkGray;
	font-size: 0.85rem;
	&.add-underline {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 3px;
	}
}

p {
	&.add-underline {
		border-bottom: 1px solid $darkGray;
		padding-bottom: 3px;
	}
	&.footnote {
		font-size: 0.75rem;
		line-height: 1.25rem !important;
	}
}

a {
	&:link,
	&:visited,
	&:active {
		color: $lightBlue;
		i,
		fa-icon {
			font-size: 125%;
			vertical-align: middle;
			margin-right: 0.5rem;
		}
	}
	&:hover,
	&:focus {
		color: $blue;
		text-decoration: none;
	}
	&.reverse-deco {
		&:link,
		&:visited,
		&:active {
			text-decoration: none;
		}
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	&.white-link {
		color: $white !important;
		&:link,
		&:visited,
		&:active {
			color: $white !important;
		}
		&:hover,
		&:focus {
			color: $gray !important;
		}
	}
	&.gray-link {
		color: $darkGray !important;
		&:link,
		&:visited,
		&:active {
			color: $darkGray !important;
		}
		&:hover,
		&:focus {
			color: $blue !important;
		}
	}
	&.red-link {
		&:link,
		&:visited,
		&:active {
			color: $red !important;
		}
		&:hover,
		&:focus {
			color: #990000 !important;
		}
	}
	&.green-link {
		&:link,
		&:visited,
		&:active {
			color: $green !important;
		}
		&:hover,
		&:focus {
			color: #006600 !important;
		}
	}
	&.dark-blue-link {
		&:link,
		&:visited,
		&:active {
			color: $pkBlue !important;
		}
		&:hover,
		&:active {
			color: $lightBlue !important;
		}
	}
	&.gray-icon {
		&:link,
		&:visited,
		&:active {
			i,
			fa-icon {
				color: $darkGray !important;
			}
		}
		&:hover,
		&:focus {
			i,
			fa-icon {
				color: $blue !important;
			}
		}
	}
	&.no-right-margin {
		i,
		fa-icon {
			margin-right: 0;
		}
	}
	&.blue-gray-icon {
		&:link,
		&:visited,
		&:active {
			i,
			fa-icon {
				color: $blueGray !important;
				font-size: 125%;
				vertical-align: middle;
				margin-right: 0.5rem;
			}
		}
		&:hover,
		&:focus {
			i,
			fa-icon {
				color: $blue !important;
			}
		}
	}
	&.no-right-margin {
		i,
		fa-icon {
			margin-right: 0;
		}
	}
	&.bigger-icon {
		i,
		fa-icon {
			font-size: 150% !important;
		}
	}
	&.disabled {
		color: $darkGray !important;
		&:link,
		&:visited,
		&:active {
			color: $darkGray !important;
			text-decoration: none;
		}
		&:hover,
		&:focus {
			color: $darkGray !important;
			text-decoration: none;
			cursor: not-allowed;
		}
	}
	// To reset the icon for special needs.
	&.reset-link-icon {
		i, fa-icon {
			font-size: unset;
			vertical-align: unset;
			margin-right: 0;
		}
	}
}

// Font Sizing
.font-size-075 {
	font-size: 0.75rem !important;
}
.font-size-085 {
	font-size: 0.85rem !important;
}
.font-size-095 {
	font-size: 0.95rem !important;
}
.font-size-1 {
	font-size: 1rem !important;
}
.font-size-125 {
	font-size: 1.25rem !important;
}
.font-size-15 {
	font-size: 1.5rem !important;
}
.font-size-175 {
	font-size: 1.75rem !important;
}
.font-size-2 {
	font-size: 2rem !important;
}

table {
	color: $darkGray;
	thead {
		th {
			border-bottom: 2px solid $outlineGray;
		}
	}
	tbody {
		tr {
			&.clickable-row {
				&:hover,
				&:focus {
					background-color: $tooltipBlue !important;
					color: $blue !important;
					transition: 0.25s ease-in-out;
					cursor: pointer;
				}
			}
		}
	}
	th,
	td {
		border-top: 1px solid $outlineGray;
		&.no-top-border {
			border-top: unset;
		}
		&.centered-content {
			text-align: center;
			vertical-align: middle;
		}
		&.clickable-cell {
			&:hover,
			&:focus {
				background-color: $tooltipBlue !important;
				color: $blue !important;
				transition: 0.25s ease-in-out;
				cursor: pointer;
			}
		}
	}
}

// New style of tabs
.nav-tabs {
	border-bottom: 2px solid $pkBlue;
	justify-content: center;
	.nav-item {
		margin-bottom: 0;
		.nav-link {
			border: 0;
			font-weight: 600;
			&.active {
				color: $pkBlue;
				font-weight: 700;
				border-bottom: 2px solid $pkBlue;
			}
		}
	}
}

hr.divider {
	border: 1px solid $pkBlue;
	&.gray {
		border-color: $darkGray;
	}
	&.outlineGray {
		border-color: $outlineGray;
	}
}

hr.divider1px {
	border-top: 1px solid $pkBlue;
	&.gray {
		border-color: $darkGray;
	}
	&.outlineGray {
		border-color: $outlineGray;
	}
}

.text-strong {
	font-weight: bold !important;
}

.medium-text {
	font-weight: 500;
}

.semibold-text {
	font-weight: 600;
}

strong,
.bold-text {
	font-weight: 700 !important;
}

// Class for this site to show Roboto Font
.roboto-font {
	font-family: $roboto;	
}

// Creates the styling for the "PowerScore" brand
.powerscore-text {
	font-family: $powerscore;
	&.slightly-bigger {
		font-size: 125%;
	}
	&::after {
		content: "\2122";
		font-size: 50%;
		vertical-align: super;
	}
}

// Class for this site to show Kanit Font
.kanit-font {
	font-family: $powerscore;
}

.blue-text {
	color: $pkBlue !important;
}

.reg-blue-text {
	color: $blue;
}

.red-text {
	color: $red !important;
}

.orange-text {
	color: $orange !important;
}

.green-text {
	color: $green !important;
}

.white-text {
	color: $white !important;
}

.black-text {
	color: $black !important;
}

.gray-text {
	color: $darkGray !important;
}

.icon-blue-text {
	color: $iconLinkBlue !important;
}

.dark-blue-text {
	color: $darkBlue !important;
}

.magenta-text {
	color: $magenta;
}

// Background Colors
.white-bg {
	background-color: $white !important;
}

.off-row-gray-bg {
	background-color: $offRowGray !important;
}

.half-blue-bg {
	background-color: $halfBlue !important;
}

.outline-gray-bg {
	background-color: $outlineGray !important;
}

.light-gray-bg {
	background-color: $lightGray !important;
}

.placeholder-gray-bg {
	background-color: $placeholderGray !important;
}

.light-green-bg {
	background-color: $lightGreen !important;
}

.faint-orange-bg {
	background-color: $faintOrange !important;
}

// Allows for elements to only hit a certain maximum width
// .width-limit will one day be retired and removed.
.maxwidth-800 {
	max-width: 800px;
}
.maxwidth-1080 {
	max-width: 1080px;
}
.maxwidth-1200 {
	max-width: 1200px;
}

.w-auto {
	width: auto !important;
}

.w-10 {
	width: 10% !important;
}

.w-15 {
	width: 15% !important;
}

.w-33 {
	width: 33% !important;
}

.w-45 {
	width: 45% !important;
}

.w-75 {
	width: 75% !important;
}

// Special bottom margin to space out content
.mb-6 {
	margin-bottom: 5rem;
}

// Needed to be able to apply this anywhere
.overflow-x-hidden {
	overflow-x: hidden;
}

.content-container {
	padding-top: 7rem;
}

// Style for shaded example divs
.example-div {
	border: 1px solid $outlineGray;
	border-radius: 0.3rem;
	&.shaded {
		background-color: $offRowGray;
	}
}

// Styling for Copy Code Block containers
.copy-block-div {
	button {
		border: 0;
		opacity: 0.6;
	}
	&.add-y-scroll span:first-of-type {
		max-height: 25rem;
    overflow-y: scroll;
    margin-top: 2.5rem;
	}
}

//  Styles for the small breakpoint.
@media (min-width: 576px) {

}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {

}

//  Styles for the large breakpoint.
@media (min-width: 992px) {

// Styling for Copy Code Block containers
.copy-block-div {
	& span:first-of-type {
		white-space: pre-wrap !important;
	}
}

}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {

	.width-limit {
		max-width: 1080px;
		margin-left: auto;
		margin-right: auto;
	}

}

//  Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {

	.copy-block-div {
		& span:first-of-type {
			white-space: pre !important;
		}
	}	

}

//  Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {

}