// For colors, fonts, and other variables
@import "../../scss/_config.scss";

// 	Header Elements
header {
  background-color: $darkBlue;
  color: $white;
  box-shadow: 0 2px 5px $darkGray;
  position: fixed;
  z-index: 1100;
  .pk-logo {
    height: 3.5rem;
    width: auto;
  }
  .navToggle {
    font-size: 1.5rem;
    padding: 0.5rem;
    i, fa-icon {
      font-size: 1.5rem;      
    }
  }
}

//  Styles for the small breakpoint.
@media (min-width: 576px) {
}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {
}

//  Styles for the large breakpoint.
@media (min-width: 992px) {
}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
}

//  Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {
}

//  Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {
}
