// For colors, fonts, and other variables
@import "../../../scss/_config.scss";

// 	Content Div Styles

// Making a rounded corner div
.rounded-corner-div {
  border: 1px solid $outlineGray;
  border-radius: 0.5rem;
	// For internal divs that might hug the corners.
	.top-rounded-corners {
		border-radius: 0.45rem 0.45rem 0 0;
	}
	.bottom-rounded-corners {
		border-radius: 0 0 0.45rem 0.45rem;
	}
}

// Top Form Container
.top-form {
  padding: 0 1rem;
  margin-bottom: 4rem;
  .form-container {
    border-top: 1px solid $pkBlue;
    border-left: 1px solid $outlineGray;
    border-right: 1px solid $outlineGray;
    border-bottom: 1px solid $outlineGray;
    border-radius: 0 0 0.5rem 0.5rem;
    .main-form-row {
      padding: 1.5rem 1rem 2.5rem;
    }
    .shifted-button {
      position: relative;
      .btn {
        position: absolute;
        top: -1.15rem;
      }
    }
    .shifted-button-div {
      position: relative;
      .shifted-div {
        position: absolute;
        top: -1.15rem;
      }
    }
  }
}

// Div section for form elements
.form-div {
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid $outlineGray;
  overflow-wrap: break-word;
  .form-divider {
    border: 1px solid $outlineGray;
  }
}

// Style for a Form Row Item Entry in Dashboard
.form-row-item-entry {
  background-color: $lightGray;
  border: 1px solid $outlineGray;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  .control-panel {
    padding: 1rem 1rem 0 1rem;
  }
  .control-panel-smaller {
    padding: 1rem 1rem 0 0.5rem;
  }
  .entry-form {
    background-color: $offWhite;
    border-radius: 0 0 0.5rem 0.5rem;
    padding-top: 1rem;
    min-height: 8rem;
  }
  &.selected {
    border: 1px solid $darkBlue;
    background-color: $gray;
  }
}

.big-number-label {
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0;
}
.big-number {
  font-size: 1.75rem;
  color: $pkBlue;
  font-weight: 700;
  line-height: 1.75rem;
  sup {
    font-size: 1rem;
  }
}

// Collapse with Chevron and Details Text
.custom-collapse {
	.collapse-header {
		a {
			&.collapse-toggle {
				&:link, &:visited, &:active, &:focus {
					color: $pkBlue;
					font-weight: 600;
					display: block;
          text-decoration: none;
					.collapse-symbol {
						.details-text {
							opacity: 0;
							transition: opacity 1s;
							display: inline-block;
							font-size: 0.75rem;
							margin-right: 0.5rem;
						}
						i, fa-icon {
							transition: 0.3s transform ease-in-out;
							vertical-align: baseline;
							font-size: 1rem;
						}
					}
				}
				&:hover {
					color: $lightBlue;
          text-decoration: none;
				}
				&.light-blue {
					&:link, &:visited, &:active, &:focus {
						color: $lightBlue;
						font-weight: 600;
            text-decoration: none;
					}
					&:hover {
						color: $blue;
            text-decoration: none;
					}
				}
				&.collapsed {
					.collapse-symbol {
						.details-text {
							opacity: 1;
						}
						i, fa-icon {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
	}
}


//  Styles for the small breakpoint.
@media (min-width: 576px) {
}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {
}

//  Styles for the large breakpoint.
@media (min-width: 992px) {
}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
}

//  Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {
}

//  Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {
}
