// 	Configuration, fonts, colors, and mixins.

// Fonts
$mainFont: "Montserrat", sans-serif;
$roboto: "Roboto", sans-serif;
$powerscore: "Kanit", sans-serif;
$helvetica: Helvetica, sans-serif;

// Colors
$white: #ffffff;
$halfWhite: rgba(255, 255, 255, 0.5);
$black: #000000;
$pkBlue: #1a2574;
$darkBlue: #214a86;
$lightBlue: #12a5ff;
$blue: #27569c;
$halfBlue: #e9eef5;
$lightGray: #ebebeb;
$gray: #d5dfec;
$darkGray: #707070;
$orange: #fc8f3b;
$blueGray: #6c91a7;
$placeholderGray: #6c757d;
$iconLinkBlue: #1e91d6;
$tooltipBlue: #cfe1fb;
$green: #009900;
$lightGreen: #ddf5dd;
$yellow: #ffe600;
$red: #ff0000;
$pink: #f5dddd;
$offWhite: #fafafa;
$silver: #d4d2c9;
$diamond: #b9f2ff;
$offRowGray: #f5f5f5;
$outlineGray: #babec2;
$darkerOutlineGray: #a1a1a1;
$faintPink: #fff1f1;
$faintOrange: #fff2d9;
$faintGreen: #f1fff1;
$faintBlue: #f2f8ff;
$magenta: #e83e8c;

// Animations
// Animation to fade items in
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// Animation to fade items out
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

// Styles
html {
    font-size: 16px;
}