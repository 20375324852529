// For colors, fonts, and other variables
@import '../../../scss/_config.scss';

// 	Toastrs Styles
// Based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css

// Position
.toast-center-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.toast-top-center {
	top: 0;
	right: 0;
	width: 100%;
}
.toast-bottom-center {
	bottom: 0;
	right: 0;
	width: 100%;
}
.toast-top-full-width {
	top: 0;
	right: 0;
	width: 100%;
}
.toast-bottom-full-width {
	bottom: 0;
	right: 0;
	width: 100%;
}
.toast-top-left {
	top: 12px;
	left: 12px;
}
.toast-top-right {
	top: 12px;
	right: 12px;
}
.toast-bottom-right {
	right: 12px;
	bottom: 12px;
}
.toast-bottom-left {
	bottom: 12px;
	left: 12px;
}

// Toast Styles
.toast-title {
	font-weight: bold;
}
.toast-message {
	word-wrap: break-word;
	a {
		color: $darkGray;
		&:hover {
			color: $gray;
			text-decoration: none;
		}
	}
	label {
		color: $darkGray;
	}
}

.toast-close-button {
	position: relative;
	right: -0.3em;
	top: -0.3em;
	float: right;
	font-size: 20px;
	font-weight: bold;
	color: $darkGray;
	text-shadow: 0 1px 0 $darkGray;
	/* opacity: 0.8; */
	&:hover, &:focus {
		color: $black;
		text-decoration: none;
		cursor: pointer;
		opacity: 0.4;
	}
}

// Additional properties for button version iOS requires the button element instead of an anchor tag. If you want the anchor version, it requires `href="#"`.
button.toast-close-button {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
}
.toast-container {
	pointer-events: none;
	position: relative !important;
//	position: fixed;
//	z-index: 999999;
}
.toast-container * {
	box-sizing: border-box;
}
.toast-container .ngx-toastr {
	position: relative !important;
	overflow: hidden;
	margin: 0 0 6px;
	padding: 15px 15px 15px 50px;
	width: 300px;
	border-radius: 3px 3px 3px 3px;
	background-position: 15px center;
	background-repeat: no-repeat;
	background-size: 24px;
	box-shadow: 0 0 12px $darkerOutlineGray;
	color: $darkGray;
	font-weight: 600;
	font-size: 0.8rem;
}
.toast-container .ngx-toastr:hover {
	box-shadow: 0 0 12px $black;
	opacity: 1;
	cursor: pointer;
}
.toast-info {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(30,145,214)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}
.toast-error {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,0,0)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}
.toast-success {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(0,153,0)' d='M504,256c0,137-111,248-248,248S8,393,8,256S119,8,256,8S504,119,504,256z M227.3,387.3l184-184c6.2-6.2,6.2-16.4,0-22.6 l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6,0L216,308.1l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6,0l-22.6,22.6c-6.2,6.2-6.2,16.4,0,22.6l104,104 C210.9,393.6,221.1,393.6,227.3,387.3L227.3,387.3z'/%3E%3C/svg%3E");
}
.toast-warning {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,110,0)' d='M504,256c0,137-111,248-248,248S8,393,8,256C8,119.1,119,8,256,8S504,119.1,504,256z M256,306c-25.4,0-46,20.6-46,46 s20.6,46,46,46s46-20.6,46-46S281.4,306,256,306z M212.3,140.7l7.4,136c0.3,6.4,5.6,11.3,12,11.3h48.5c6.4,0,11.6-5,12-11.3l7.4-136 c0.4-6.9-5.1-12.7-12-12.7h-63.4C217.4,128,212,133.8,212.3,140.7L212.3,140.7z'/%3E%3C/svg%3E");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
	width: 300px;
	margin-left: auto;
	margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
	width: 96%;
	margin-left: auto;
	margin-right: auto;
}
.ngx-toastr {
	background-color: #030303;
	pointer-events: auto;
}
.toast-success {
	background-color: $white;
	border: 1px solid $pkBlue;
}
.toast-error {
	background-color: $white;
	border: 1px solid $pkBlue;
}
.toast-info {
	background-color: $white;
	border: 1px solid $pkBlue;
}
.toast-warning {
	background-color: $white;
	border: 1px solid $pkBlue;
}
.toast-progress {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 4px;
	background-color: $black;
	opacity: 0.4;
}

// Responsive Design
@media all and (max-width: 240px) {
	.toast-container .ngx-toastr.div {
		padding: 8px 8px 8px 50px;
		width: 11em;
	}
	.toast-container .toast-close-button {
		right: -0.2em;
		top: -0.2em;
	}
}
@media all and (min-width: 241px) and (max-width: 480px) {
	.toast-container .ngx-toastr.div {
		padding: 8px 8px 8px 50px;
		width: 18em;
	}
	.toast-container .toast-close-button {
		right: -0.2em;
		top: -0.2em;
	}
}
@media all and (min-width: 481px) and (max-width: 768px) {
	.toast-container .ngx-toastr.div {
		padding: 15px 15px 15px 50px;
		width: 25em;
	}
}