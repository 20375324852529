// For colors, fonts, and other variables
@import '../../../scss/_config.scss';

// 	Modals Styles
.modal {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
	&.fade {
		.modal-dialog {
			&.modal-dialog-slideout {
				-webkit-transform: translate(100%, 0) scale(1);
				transform: translate(100%, 0) scale(1);
			}
		}
		&.show {
			.modal-dialog {
				&.modal-dialog-slideout {
					-webkit-transform: translate(0, 0);
					transform: translate(0, 0);
					display: flex;
					align-items: stretch;
					-webkit-box-align: stretch;
					height: 100%;
					.modal-body {
						overflow-y: auto;
						overflow-x: hidden;
					}
				}
			}
		}
	}
}

.md .modal-dialog {
	max-width: 565px;
}

// General styles for modals
.pk-modal {
	font-family: $mainFont;
	span.value {
		img {
			max-width: 100% !important;
		}
	}
	.modal-content {
		border: 1px solid $pkBlue;
		border-radius: 0.5rem;
		.modal-header {
			background-color: $pkBlue;
			color: $white;
			border-radius: 0.4rem 0.4rem 0 0;
			padding: 1rem 1.5rem;
			.modal-title {
				font-size: 1rem;
				font-weight: 700;
				color: $white;
			}
			.close {
				float: none;
				text-shadow: none;
				opacity: 1;
				color: $white;
        background: none;
        border: none;
				&:hover,
				&:focus {
					color: $gray;
				}
			}
		}
		.modal-body {
			font-size: 0.8rem;
			padding: 1.5rem;
			color: $darkGray;
			.big-blue-text {
				color: $pkBlue;
				font-size: 1.2rem;
				font-weight: 600;
			}
			.deposit-required {
				font-weight: 700;
				color: $red !important;
			}
			.value {
				a {
					i,
					fa-icon {
						font-size: 1rem;
						vertical-align: middle;
						margin-right: 0rem;
					}
				}
			}
			.top-row-actions {
				.btn {
					color: #1e91d6;
				}
			}
		}
		.modal-top-actions-bar {
			.modal-top-actions {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-end;
			}
		}
		.modal-footer {
			border-top: none;
			border-bottom-right-radius: 0.4rem;
			border-bottom-left-radius: 0.4rem;
			padding: 1.5rem 1.5rem;
		}
	}
	.modal-full,
	&.modal-full {
		max-width: 90vw;
	}
	.modal-lg,
	&.modal-lg {
		max-width: 800px;
	}

	&.modal-dialog-slideout {
		min-height: 100%;
		margin: 0 0 0 auto;
		background: $white;
		border-left: 1px solid $darkBlue;
		.modal-content {
			border: 0;
			.modal-header {
				background-color: $darkBlue;
				padding: 1rem 1.5rem;
				border-radius: 0;
				color: $white;
			}
			.modal-body {
				padding: 0;
				font-size: 0.85rem;
				.modal-top {
					background-color: $darkBlue;
					.close {
						text-shadow: none;
						opacity: 1;
						color: $white;
						&:hover,
						&:focus {
							color: $gray;
							outline: unset;
						}
					}
					.go-back {
						text-shadow: none;
						opacity: 1;
						color: $white;
						font-weight: 600;
						position: relative;
						bottom: 8px;
						&:hover,
						&:focus {
							color: $gray;
							outline: unset;
							text-decoration: none;
						}
						i,
						fa-icon {
							font-size: 1.25rem;
							vertical-align: bottom;
							margin-right: 0.5rem;
							position: relative;
							top: 5px;
						}
					}
					label {
						color: $white;
					}
					.form-symbol {
						color: $darkGray;
						top: 2.3rem;
					}
				}
				.grey-row {
					background-color: $lightGray;
				}
				.faq-answer {
					p {
						margin: 0;
					}
				}
				.search-panel {
					background-color: $white;
				}
				.feedback-panel {
					background-color: $lightGray;
					padding-bottom: 1rem;
					height: 100vh;
					.modal-top {
						background-color: $lightGray;
						.close,
						.go-back {
							color: $blue;
							&:hover,
							&:focus {
								color: $lightBlue;
							}
						}
					}
					.feedback-content {
						margin-top: 10vh;
						.form-control {
							background-color: $offRowGray;
							border: 2px solid $outlineGray;
							border-radius: 0.25rem;
							font-size: 0.85rem;
							font-weight: 500;
						}
					}
				}
				h4 {
					color: $darkGray;
				}
				.search-commodity-icon {
					height: 3rem;
					width: auto;
				}
				.larger-side-icon {
					i,
					fa-icon {
						font-size: 3rem;
						color: $blueGray;
					}
					a {
						i,
						fa-icon {
							font-size: 3rem;
							color: $blueGray;
							margin-right: 0;
							vertical-align: bottom;
						}
					}
				}
				ul,
				ol {
					li {
						margin-bottom: 0.5rem;
					}
				}
				.alt-lead-account {
					background-color: $offRowGray;
					margin-top: 2rem;
					padding-top: 1rem;
					padding-bottom: 1rem;
					.energy-icon {
						height: 2.5rem;
						width: auto;
					}
					.collapse-link {
						font-weight: 600;
						&::before {
							content: "Less Info";
						}
						&.collapsed {
							&::before {
								content: "More Info";
							}
						}
					}
				}
			}
		}
		.modal-footer {
			background-color: $lightGray;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-top: none;
			padding: 1rem 0;
			font-size: 0.85rem;
			.initial-link {
				font-weight: 600;
				i,
				fa-icon {
					font-size: 1.25rem;
					vertical-align: bottom;
					margin-right: 0.5rem;
				}
			}
			.left-link {
				padding: 0.25rem 0.75rem 0.25rem 0;
				font-weight: 600;
			}
			.right-link {
				padding: 0.25rem 0 0.25rem 0.75rem;
				border-left: 1px solid $pkBlue;
				font-weight: 600;
			}
		}
	}

}

//  Styles for the small breakpoint.
@media (min-width: 576px) {

}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {

}

//  Styles for the large breakpoint.
@media (min-width: 992px) {

}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {

}

//  Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {

}

//  Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {

}